import React from 'react'
import FeaturedInfo from '../../components/FeaturedInfo/FeaturedInfo'
import './home.scss'

export default function Home() {
  return (
    <div className='home'>
        <FeaturedInfo />
    </div>
  )
}
